import React from 'react'
import { Flex } from 'rebass'

import DecodeContent from './DecodeContent'
import FlexWrapper from './FlexWrapper'

const Hero = ({ heroContent }) => (
  <div className="hero">
    <div className="custom-container">
      <div className="content-wrapper">
        <FlexWrapper flexClassName="flex-holder">
          <div className="info-wrapper">
            <h1 className="subheading">{heroContent.title}</h1>
            <div className="t-desc"><DecodeContent objVal={heroContent.desc} /></div>
          </div>
          {/* {heroContent.icon} */}
          <div className="bg-wrapper" style={{backgroundImage: "url(" + heroContent.icon.default + ")"}}></div>
          <div className="image-wrapper">
            <img src={heroContent.icon.default} alt={heroContent.title}/>
          </div>
        </FlexWrapper>
      </div>
    </div>
  </div>
)

export default Hero