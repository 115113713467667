import React, { useContext } from "react";
import { Helmet } from "react-helmet";

import { Context } from "../context/GlobalContext";

import Hero from "../components/Hero";
import Form from "../components/Form";
import FormButton from "../components/FormButton";

const heroContent = {
  title: "Customer export",
  desc: `<p>If you need to move your customer data around, this tool will email you an export the main customer data records as a .CSV file. It’s not currently possible to get some of the more advanced customer details - you should use our API for that, or view them in the Billsby control panel.</p>
  
  <p>If you filter by start date and end date, we’ll only export customers who were created between those dates. Great for accessing only your most recent clients.</p>`,
  icon: require("../assets/images/hero-customer.svg"),
};

const formBtnName = "Send customers as a .CSV";

const CustomerExport = () => {
  const { handleGenerateCSV, errorMsg } = useContext(Context);

  return (
    <main className="main-customer">
      <Helmet>
        <title>Customer Export | Billsby Labs</title>
      </Helmet>
      <Hero heroContent={heroContent} />
      <Form>
        <FormButton
          handleAction={() => handleGenerateCSV("customers")}
          name={formBtnName}
        />
        {errorMsg && <div className="error-message">{errorMsg}</div>}
      </Form>
    </main>
  );
};

export default CustomerExport;
