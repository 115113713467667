import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <main className="main-home">
      <Helmet>
        <title>Billsby Labs</title>
      </Helmet>
      <div className="custom-container">
        <div className="content-wrapper">
          <h1 className="heading">Experiments in progress</h1>
          <p>
            Our Labs team are always working on new features to improve and
            enhance your Billsby experience and power the next generation of
            subscription billing. Visit our main websites to see what we're
            working on.
          </p>
          <Link to="/invoice-insights" className="btn-black">
            Find out more
          </Link>
        </div>
      </div>
    </main>
  );
};

export default Index;
