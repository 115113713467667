import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const FormButton = ({ handleAction, name }) => {
  const { generatorStatus } = useContext(Context);

  const [btnLabel, setBtnLabel] = useState(name);

  useEffect(() => {
    if (generatorStatus === "default") setBtnLabel(name);
    else if (generatorStatus === "sending") setBtnLabel("Sending...");
    else if (generatorStatus === "sent") setBtnLabel("Sent");
    else if (generatorStatus === "invalid-api-key")
      setBtnLabel("Invalid API Key");
    else if (generatorStatus === "generate-failed")
      setBtnLabel("CSV generation failed - try again later");
    // eslint-disable-next-line
  }, [generatorStatus]);

  return (
    <button
      className={
        generatorStatus === "default" ||
        generatorStatus === "sending" ||
        generatorStatus === "sent"
          ? `labs-form-btn-generate btn btn-orange ${btnLabel === "Sending..." && 'loading-generate'}`
          : `labs-form-btn-generate btn btn-red ${btnLabel === "Sending..." && 'loading-generate'}`
      }
      onClick={handleAction}
    >
      {generatorStatus === "sent" ? (
        <FontAwesomeIcon icon={faCheckCircle} />
      ) : (
        ""
      )}
      {generatorStatus === "invalid-api-key" ||
      generatorStatus === "generate-failed" ? (
        <FontAwesomeIcon icon={faTimesCircle} />
      ) : (
        ""
      )}
      <span>{btnLabel}</span>
    </button>
  );
};

export default FormButton;
