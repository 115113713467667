import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Flex } from "rebass";
import history from "../history";

import LogoSVG from "../assets/images/logo.svg";
import { Context } from "../context/GlobalContext";

const Header = () => {
  const { handleModal, showWhatIsLabs } = useContext(Context);

  return (
    <header className="page-header">
      <div className="custom-container">
        <div className="content-wrapper">
          <Flex justifyContent="space-between" alignItems="center">
            <div className="navbar-brand">
              <Link to="/" className="navbar-logo">
                <img src={LogoSVG} alt="Billsby Labs" />
                <span className="t-regular">LABS</span>
              </Link>
            </div>
            {showWhatIsLabs && (
              <div className="modal-action-holder">
                <button
                  type="button"
                  className="t-modal-action"
                  onClick={handleModal}
                >
                  What is Labs?
                </button>
              </div>
            )}
          </Flex>
        </div>
      </div>
    </header>
  );
};

export default Header;
