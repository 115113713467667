import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import { Helmet } from "react-helmet";

// import "./App.css";
import "./styles/main.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Index from "./pages/Index";
import InvoiceInsights from "./pages/Invoice";
import CustomerExport from "./pages/Customer";
import SubscriptionExport from "./pages/Subscription";
import TestImport from "./pages/TestImport";

import GlobalContext from "./context/GlobalContext";

function App() {
  return (
    <Router history={history}>
      <GlobalContext>
        <div className="App">
          <Helmet>
            <script
              async
              defer
              src="https://scripts.simpleanalyticscdn.com/latest.js"
            ></script>
            <noscript>
              {` <img
              src="https://queue.simpleanalyticscdn.com/noscript.gif"
              alt=""
            />`}
            </noscript>
          </Helmet>
          <Header />
          <div className="app-wrapper">
            <Switch>
              <Route exact path="/" component={Index} />
              <Route path="/invoice-insights" component={InvoiceInsights} />
              <Route path="/customer-export" component={CustomerExport} />
              <Route
                path="/subscription-export"
                component={SubscriptionExport}
              />
              <Route path="/test-import" component={TestImport} />
            </Switch>
          </div>
          <Footer />
        </div>
      </GlobalContext>
    </Router>
  );
}

export default App;
