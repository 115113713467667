import React, { useContext } from 'react'
import { Context } from '../context/GlobalContext'

import Modal from './Modal'

const Footer = () => {
  const { openModal, handleModal } = useContext(Context)

  return (
    <React.Fragment>
      <footer className="page-footer">
        <div className="custom-container">
          <div className="content-wrapper">
            <p >&copy;Billsby({new Date().getFullYear()})</p>
            <p>A product of <a href="/">Airfi Networks</a></p>
          </div>
        </div>
      </footer>
      <Modal />
      {openModal && <div className="overlay" onClick={handleModal}><div className="modal-close" onClick={handleModal}>
        X
      </div></div>}
    </React.Fragment>
  )
}

export default Footer