import React, { useEffect, useState } from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import APIPaths from '../lib/APIPaths';

export const Context = React.createContext();

export const GlobalContext = ({ children }) => {
  const location = useLocation();

  const [billsbyUrl, setBillsbyURL] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
  );
  const [dateTo, setDateTo] = useState(
    moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
  );
  const [emailTo, setEmailTo] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [showWhatIsLabs, setShowWhatIsLabs] = useState(false);
  const [generatorStatus, setGeneratorStatus] = useState('default');
  const [uploadedContent, setUploadedContent] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  /* ----------------------------- Handle onChange ---------------------------- */

  const handleOnChange = (event) => {
    if (event.target.name === 'apiKey') setApiKey(event.target.value);
    else if (event.target.name === 'billsbyUrl')
      setBillsbyURL(event.target.value);
    else if (event.target.name === 'dateFrom') setDateFrom(event.target.value);
    else if (event.target.name === 'dateTo') setDateTo(event.target.value);
    else if (event.target.name === 'email') setEmailTo(event.target.value);
    else {
      console.log('do nothing');
    }
  };

  /* ----------------------------- Payload Checker ---------------------------- */

  const payloadChecker = (type) => {
    let errorList = [];
    if (billsbyUrl === '') errorList.push('Billsby URL is missing');
    if (apiKey === '') errorList.push('API Key is missing');
    if (type === 'export') {
      if (dateFrom === '') errorList.push('Date From is missing');
      if (dateTo === '') errorList.push('Date To is missing');
    }
    if (emailTo === '') errorList.push('Email is missing');

    // console.log(errorList);
    // You can also add this errorList to a notification before return

    return errorList;
  };

  /* ---------------------- handle generate / export csv ---------------------- */

  const handleGenerateCSV = async (type) => {
    console.log('Generating CSV ' + type);
    setGeneratorStatus('sending');
    const payloadChecked = payloadChecker('export');

    setErrorMsg('');

    if (payloadChecked.length !== 0) {
      setTimeout(() => {
        setGeneratorStatus('generate-failed');
        setTimeout(() => {
          setGeneratorStatus('default');
        }, 10000);
      }, 3500);
      console.log(payloadChecked);
      setErrorMsg(payloadChecked[0]);

      return;
    }

    let url = '';
    if (type === 'invoices') url = APIPaths.exports_invoices;
    else if (type === 'customers') url = APIPaths.exports_customers;
    else if (type === 'subscriptions') url = APIPaths.exports_subscriptions;
    else
      return setTimeout(() => {
        setGeneratorStatus('generate-failed');
        setTimeout(() => {
          setGeneratorStatus('default');
        }, 15000);
      }, 3500);

    await axios
      .post(url, {
        billsbyUrl,
        apiKey,
        dateFrom,
        dateTo,
        emailTo
      })
      .then((result) => {
        // console.log(result);
        // let filename = `${billsbyUrl}-${type}(${dateFrom}-to-${dateTo}).csv`;
        setTimeout(() => {
          // fileDownload(result.data, filename);
          setGeneratorStatus('sent');
          console.log(1);
          setTimeout(() => {
            setGeneratorStatus('default');
          }, 15000);
        }, 3500);
      })
      .catch((err) => {
        // Return error
        if (err.response.data !== undefined) {
          setTimeout(() => {
            let status = 'generate-failed';

            if (err.response.data.error_code === 'API_KEY_ERROR')
              status = 'invalid-api-key';

            if (err.response.data.message === '')
              setErrorMsg(
                'Execution Error. Check Billsby URL and API Key if correct then Try again.'
              );
            else setErrorMsg(err?.response?.data?.message);

            setGeneratorStatus(status);

            setTimeout(() => {
              setGeneratorStatus('default');
            }, 15000);
          }, 3500);
        }
      });
  };

  /* ---------------------------- Handle Upload csv --------------------------- */
  const handleImportCSV = (type, file) => {
    setUploadedContent([]);
    setErrorList([]);

    const payloadChecked = payloadChecker('import');

    if (payloadChecked.length === 0) {
      console.log('Uploading CSV ' + type);
      setGeneratorStatus('uploading');
      const fileChecker = true;

      if (fileChecker) {
        let url = '';
        if (type === 'invoices') url = APIPaths.imports_invoices;
        else if (type === 'customers') url = APIPaths.imports_customers;
        else if (type === 'subscriptions') url = APIPaths.imports_subscriptions;
        else {
          setTimeout(() => {
            setGeneratorStatus('upload-failed');
            setTimeout(() => {
              setGeneratorStatus('default');
            }, 15000);
          }, 3500);
        }

        let formData = new FormData();
        formData.append('csvfile', file);
        formData.append('billsbyUrl', billsbyUrl);
        formData.append('apiKey', apiKey);

        axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((result) => {
            console.log(result);
            setUploadedContent(result.data.data);
            // setTimeout(() => {
            setGeneratorStatus('uploaded');
            console.log(1);
            setTimeout(() => {
              setGeneratorStatus('default');
            }, 15000);
            // }, 3500);
          })
          .catch((err) => {
            console.log(err.response.data);
            setErrorList([err.response.data]);
            setGeneratorStatus('error');
            setTimeout(() => {
              setGeneratorStatus('default');
            }, 15000);
            // Return error
            // if (err.response.data !== undefined) {
            //   if (err.response.data.error_code === "API_KEY_ERROR") {
            //     setTimeout(() => {
            //       setGeneratorStatus("invalid-api-key");
            //       setTimeout(() => {
            //         setGeneratorStatus("default");
            //       }, 15000);
            //     }, 3500);
            //   } else {
            //     setTimeout(() => {
            //       setGeneratorStatus("upload-failed");
            //       setTimeout(() => {
            //         setGeneratorStatus("default");
            //       }, 15000);
            //     }, 3500);
            //   }
            // }
          });
      } else {
        setTimeout(() => {
          setGeneratorStatus('upload-failed');
          setTimeout(() => {
            setGeneratorStatus('default');
          }, 10000);
        }, 3500);
      }
    } else {
      setGeneratorStatus('upload-failed');
      setErrorList([{ error_details: payloadChecked }]);
      setTimeout(() => {
        setGeneratorStatus('default');
        setErrorList([]);
      }, 5000);
      console.log(payloadChecked);
    }
  };
  /* ------------------------------ Handle modal ------------------------------ */

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    console.log(location);
    if (location.pathname !== '/') {
      console.log('Show labs');
      setShowWhatIsLabs(true);
    } else {
      setShowWhatIsLabs(false);
    }
  }, [location]);

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Context.Provider
      value={{
        apiKey,
        setApiKey,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        billsbyUrl,
        setBillsbyURL,
        emailTo,
        setEmailTo,

        showWhatIsLabs,
        generatorStatus,
        errorList,
        errorMsg,
        uploadedContent,

        handleOnChange,
        handleGenerateCSV,
        handleImportCSV,

        openModal,
        setOpenModal,
        handleModal
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default GlobalContext;
