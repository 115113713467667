import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../context/GlobalContext";

const Modal = () => {
  const { openModal, handleModal } = useContext(Context);

  return (
    <div className={`modal-wrapper ${openModal && "show"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="subheading">Try things. Make things. Break things.</h2>
          </div>
          <div className="modal-body">
            <p>
              Our customers often tell us about great ideas they have for Billsby,
              but adding new features to our core product can take a lot of time.
              Labs is a way for us to rapidly iterate on new feature ideas and see
              if they’re popular amongst our customers.
            </p>
            <p>
              Some labs projects might make their way into our core product in the
              future. Others might stay labs projects for ever. And others still
              might not last forever. But we’re always working on what’s new.
            </p>
            <a
              href="https://www.billsby.com/labs"
              target="_blank"
              className="btn-orange"
            >
              Find more tools
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
