import React from "react"
import Parser from 'html-react-parser';

const DecodeContent = ({objVal}) => {
  const htmlToReact = () => {
    const decodeObj = objVal
    return Parser(decodeObj)
  }

  return (
    <React.Fragment>
      {htmlToReact()}
    </React.Fragment>
  )
}

export default DecodeContent