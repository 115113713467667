import React, { useContext } from "react";
import { Helmet } from "react-helmet";

import { Context } from "../context/GlobalContext";

import Hero from "../components/Hero";
import Form from "../components/Form";
import FormButton from "../components/FormButton";

// import { ReactComponent as InvoiceSVG } from '../assets/images/hero-invoice.svg'

const heroContent = {
  title: "Invoice insights",
  desc: `<p>You can use this tool to be emailed an export of all of the invoices in your account for a specified period, to analyse further in Google Sheets, Microsoft Excel or any other product that accepts .CSV files.</p>
  
  <p>To make analysis easier, we’ve added a TRUE/FALSE column that checks whether each invoice is the first for a specific customer - so you can see what percentage of your revenue is new vs. existing.</p>`,
  icon: require("../assets/images/hero-invoice.svg"),
};

const formBtnName = "Send invoices as a .CSV";

const InvoiceInsights = () => {
  const { handleGenerateCSV } = useContext(Context);

  return (
    <main className="main-invoice">
      <Helmet>
        <title>Invoice Insights | Billsby Labs</title>
      </Helmet>
      <Hero heroContent={heroContent} />
      <Form>
        <FormButton
          handleAction={() => handleGenerateCSV("invoices")}
          name={formBtnName}
        />
      </Form>
    </main>
  );
};

export default InvoiceInsights;
