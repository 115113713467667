const baseURL = process.env.REACT_APP_BE_URL;
// const baseURL = "http://localhost:9000";

const APIPaths = {
  base_endpoint: baseURL,
  exports_invoices: baseURL + "/exports/invoices",
  exports_customers: baseURL + "/exports/customers",
  exports_subscriptions: baseURL + "/exports/subscriptions",
  imports_invoices: baseURL + "/imports/invoices",
  imports_customers: baseURL + "/imports/customers",
  imports_subscriptions: baseURL + "/imports/subscriptions",
};
export default APIPaths;
