import React, { useContext } from "react";
import { Flex } from "rebass";

import { Context } from "../context/GlobalContext";

import FlexWrapper from "../components/FlexWrapper";

const Form = ({ children }) => {
  const { apiKey, billsbyUrl, dateFrom, dateTo, emailTo, handleOnChange } = useContext(
    Context
  );

  return (
    <div className="custom-container">
      <div className="content-wrapper">
        <div className="labs-form">
        <FlexWrapper flexClassName="labs-form-group">
            <div className="labs-form-label">Email address</div>
            <div className="labs-form-input">
              <input
                type="email"
                onChange={handleOnChange}
                value={emailTo}
                name="email"
              />
              <p className="t-note">
                This is the email address you would like the .CSV export sent to
              </p>
            </div>
          </FlexWrapper>
          <FlexWrapper flexClassName="labs-form-group">
            <div className="labs-form-label">Billsby URL</div>
            <div className="labs-form-input url-group">
              <input
                className="url"
                type="text"
                onChange={handleOnChange}
                value={billsbyUrl}
                name="billsbyUrl"
              />
              <div className="input-ext">
                <div className="t-input">.billsby.com</div>
              </div>
            </div>
          </FlexWrapper>
          <FlexWrapper flexClassName="labs-form-group">
            <div className="labs-form-label">API KEY</div>
            <div className="labs-form-input">
              <input
                type="text"
                onChange={handleOnChange}
                value={apiKey}
                name="apiKey"
              />
              <p className="t-note">
                You’ll find this in your Billsby account, under Settings &gt;
                Webhooks and API Keys
              </p>
            </div>
          </FlexWrapper>
          <FlexWrapper flexClassName="labs-form-group">
            <div className="labs-form-label">Start Date</div>
            <div className="labs-form-input">
              <input
                type="date"
                onChange={handleOnChange}
                value={dateFrom}
                name="dateFrom"
              />
            </div>
          </FlexWrapper>
          <FlexWrapper flexClassName="labs-form-group">
            <div className="labs-form-label">End Date</div>
            <div className="labs-form-input">
              <input
                type="date"
                onChange={handleOnChange}
                value={dateTo}
                name="dateTo"
              />
            </div>
          </FlexWrapper>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Form;
