import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/GlobalContext";
import FlexWrapper from "../components/FlexWrapper";

const TestImport = () => {
  const {
    handleImportCSV,
    uploadedContent,
    errorList,
    generatorStatus,
    handleOnChange,
    billsbyUrl,
    apiKey,
    setBillsbyURL,
    setApiKey,
  } = useContext(Context);

  const [uploadedFile, setUploadedFile] = useState({});
  const fileOnChange = (e) => {
    setUploadedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (uploadedContent.length > 0) {
      Object.keys(uploadedContent[0]).forEach((key) => console.log(key));
    }
  }, [uploadedContent]);

  // Please delete / comment when deployed to live
  // useEffect(() => {
  //   setBillsbyURL("");
  //   setApiKey("");
  // }, []);

  return (
    <div className="test-page">
      <div className="test-page-title">Test Import</div>

      <div className="labs-form">
        <FlexWrapper flexClassName="labs-form-group">
          <div className="labs-form-label">Billsby URL</div>
          <div className="labs-form-input url-group">
            <input
              className="url"
              type="text"
              onChange={handleOnChange}
              value={billsbyUrl}
              name="billsbyUrl"
            />
            <div className="input-ext">
              <div className="t-input">.billsby.com</div>
            </div>
          </div>
        </FlexWrapper>
        <FlexWrapper flexClassName="labs-form-group">
          <div className="labs-form-label">API KEY</div>
          <div className="labs-form-input">
            <input
              type="text"
              onChange={handleOnChange}
              value={apiKey}
              name="apiKey"
            />
            <p className="t-note">
              You’ll find this in your Billsby account, under Settings &gt;
              Webhooks and API Keys
            </p>
          </div>
        </FlexWrapper>
        <FlexWrapper flexClassName="labs-form-group">
          <div className="labs-form-label">File</div>
          <div className="labs-form-input">
            <input type="file" onChange={fileOnChange} accept=".csv" />
          </div>
        </FlexWrapper>

        <div className="test-upload-group">
          <button onClick={() => handleImportCSV("customers", uploadedFile)}>
            Upload Customers
          </button>
          <button onClick={() => handleImportCSV("invoices", uploadedFile)}>
            Upload Invoices
          </button>
          <button
            onClick={() => handleImportCSV("subscriptions", uploadedFile)}
          >
            Upload Subscriptions
          </button>
        </div>
      </div>

      <div className="test-upload-status">Upload Status: {generatorStatus}</div>

      {uploadedContent.length > 0 && (
        <div className="test-uploaded-list">
          <ul>
            {uploadedContent.map((content) => (
              <li>
                {/* {JSON.stringify(content)} */}
                {Object.keys(content).map((key) => (
                  <span>
                    <b>{key}</b>: <i>{content[key]}</i>
                  </span>
                ))}
              </li>
            ))}
          </ul>
        </div>
      )}

      {errorList.length > 0 && (
        <div className="test-error-list">
          <div className="test-error-title">{errorList[0].error_message}</div>
          <ul>
            {errorList[0].error_details !== undefined &&
              errorList[0].error_details.map((err) => <li>{err}</li>)}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TestImport;
